import React from "react";
import { FormSection, Layout, Table } from "@vwfs-bronson/bronson-react";
import { useSummary } from "../../../../hooks/useSummary";
// import { financialFormat } from "../../../../helpers/financialFormat";
import { financialFormat } from "../../../../helpers/financialFormat";
import { currencyFormat } from "../../../../helpers/currencyFormat";
import { calculateHostDiscount } from "../../../../helpers/calculateHostDiscount";

export const ExtraServicesResume = () => {
    const { selectedMantenimentoStandAlone, selectedSeguroAutoStandAlone } = useSummary();
    if (
        selectedMantenimentoStandAlone === null &&
        (selectedSeguroAutoStandAlone?.SeguroContratado === null ||
            selectedSeguroAutoStandAlone?.SeguroContratado === undefined)
    )
        return null;

    const TieneAportacion =
        selectedSeguroAutoStandAlone?.SeguroContratado?.grupoPaquetes?.filter(
            (x) => x.Aportaciones[0]?.NumeroCuotasAportacion > 0
        ).length > 0
            ? true
            : false;

    const PaqueteSelected = selectedMantenimentoStandAlone?.Paquetes.find((x) => x.Selected === true);

    const totalServices =
        PaqueteSelected?.Servicio?.ImporteBrutoTarifa ??
        0 + selectedSeguroAutoStandAlone?.SeguroContratado?.grupoPaquetes[0]?.Paquetes[0]?.ImporteNetoTarifa ??
        0;

    return (
        <Layout.Item className="u-mt-none" default="1/1">
            <FormSection defaultOpen={true} className="u-mb-small" title={"Servicios incluidos"}>
                <Table bordered colored>
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th>Total Servicios</Table.Th>
                            <Table.Th className="u-p-none u-text-center"></Table.Th>
                            <Table.Th className="u-p-none u-text-center">
                                {financialFormat(totalServices)} €/mes
                            </Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody testId="table-head-01">
                        {selectedMantenimentoStandAlone !== null && (
                            <Table.Tr>
                                <Table.Th>{selectedMantenimentoStandAlone?.Descripcion}</Table.Th>
                                <Table.Td className="u-text-center">
                                    {financialFormat(PaqueteSelected?.Servicio?.ImporteBrutoTarifa) + " €/mes"}
                                </Table.Td>
                                <Table.Td className="u-text-center">
                                    {PaqueteSelected?.Aportacion?.NumeroCuotasAportacion} meses por{" "}
                                    {currencyFormat(PaqueteSelected?.Aportacion?.ImporteDescuentoCuotasAportadas / 100)}{" "}
                                    / mes
                                </Table.Td>
                            </Table.Tr>
                        )}
                        {selectedSeguroAutoStandAlone?.SeguroContratado !== null &&
                            selectedSeguroAutoStandAlone?.SeguroContratado !== undefined && (
                                <Table.Tr>
                                    <Table.Th>{selectedSeguroAutoStandAlone?.SeguroContratado?.ServiceName}</Table.Th>
                                    <Table.Td className="u-text-center">
                                        {financialFormat(
                                            selectedSeguroAutoStandAlone?.SeguroContratado?.grupoPaquetes[0]
                                                ?.Paquetes[0]?.ImporteNetoTarifa
                                        )}
                                        {" €/mes"}
                                    </Table.Td>
                                    <Table.Td className="u-text-center">
                                        {TieneAportacion && (
                                            <>
                                                {
                                                    selectedSeguroAutoStandAlone?.SeguroContratado?.grupoPaquetes[0]
                                                        ?.Aportaciones[0]?.NumeroCuotasAportacion
                                                }{" "}
                                                meses por{" "}
                                                {financialFormat(
                                                    calculateHostDiscount(
                                                        selectedSeguroAutoStandAlone?.SeguroContratado?.grupoPaquetes[0]
                                                            ?.Aportaciones[0]?.ImporteNetoTarifa,
                                                        selectedSeguroAutoStandAlone?.SeguroContratado?.grupoPaquetes[0]
                                                            ?.Aportaciones[0]?.PorcentajeAportacion
                                                    )
                                                )}
                                                {" €/mes"}
                                            </>
                                        )}
                                    </Table.Td>
                                </Table.Tr>
                            )}
                    </Table.Tbody>
                </Table>
            </FormSection>
        </Layout.Item>
    );
};
