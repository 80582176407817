import { useReducer } from "react";
import OfferService from "../services/OfferService";
import { useLoading } from "./useLoading";
import { useDispatch } from "react-redux";
import { addNotificationError } from "../actions/notificationAction";
import { useState } from "react";

const reducer = (state, action) => {
    switch (action.type) {
        case "get":
            return { ...action.payload };
        default:
            return state;
    }
};

export const useSearchOffers = () => {
    const [{ Simulations: simulations, Offer: offer }, dispatchSearchOffers] = useReducer(reducer, {
        Simulations: null,
        Offer: null,
    });
    const [isSearched, setIsSearched] = useState(false);
    const [{ isLoading }, { start, stop }] = useLoading();
    const [{ isLoading: isLoadingOperation }, { start: startOperation, stop: stopOperation }] = useLoading();
    const dispatch = useDispatch();

    const getOffers = async (code) => {
        start();

        const offerService = new OfferService();
        const { data, status, errors } = await offerService.recoverOffers({ CodigoOferta: code });

        setIsSearched(true);

        if (status !== 200) {
            stop();
            dispatch(addNotificationError(errors));
            return;
        }

        dispatchSearchOffers({
            type: "get",
            payload: data,
        });

        stop();
    };

    const sendOfferToOperation = async (offer) => {
        startOperation();
        const offerService = new OfferService();
        const { status, errors } = await offerService.createOperation(offer);

        stopOperation();

        if (status !== 200) {
            stop();
            dispatch(addNotificationError(errors));
            return;
        }

        await getOffers(offer.Code);
    };

    return {
        simulations,
        offer,
        isLoading,
        isLoadingOperation,
        isSearched,
        getOffers,
        sendOfferToOperation,
    };
};
