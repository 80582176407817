import { postRequest } from "../config/axiosClient";
import { types } from "../types/types";
import { addNotificationError } from "./notificationAction";

export const redirectCheckout = (token) => {
    return async (dispatch) => {
        const { data, status, errors } = await postRequest("fsoneviewservice/checkout", `${token}`);

        if (status !== 200) {
            dispatch(addNotificationError(errors));
            return;
        }

        if (data?.Codigo) {
            window.location.href = data.Codigo;
        }
    };
};

export const setMaxOffersToSend = (payload) => ({
    type: types.globalSetMaxOffersToSend,
    payload: payload,
});

export const setCalculatorType = (payload) => ({
    type: types.globalSetCalculatorType,
    payload: payload,
});

export const setIsStarted = (payload) => ({
    type: types.globalSetIsStarted,
    payload: payload,
});

export const setIsPricesWithTax = (payload) => ({
    type: types.globalSetisPricesWithTax,
    payload: payload,
});

export const setHasAccessToLongDrive = (payload) => ({
    type: types.globalSetHasAccessToLongDrive,
    payload: payload,
});

export const setReturnSalesassistInfo = (payload) => ({
    type: types.globalSetreturnSalesassistInfo,
    payload: payload,
});

export const setIsSalesAssist = (payload) => ({
    type: types.globalSetIsSalesAssist,
    payload: payload,
});
